<template>
  <div class="relative w-full h-full">
    <div class="absolute top-5 lg:top-8 flex z-50 p-4 space-x-2 justify-center items-center w-full text-gray-800">
      <button
        v-for="region in regions" :key="region.id" @click="toggleRegion(region)"
        class=" transition-all duration-300 group flex items-center text-xs lg:text-sm font-medium py-2 px-3 rounded shadow shadow-gray-300"
        :class="selected_regions.includes(region.id) ? 'bg-heimatgenuss-pink text-white shadow-heimatgenuss-pink': 'bg-white hover:bg-pink-500 hover:text-white hover:shadow-pink-500' "
      >
        <span>{{ region.title }}</span>
      </button>
    </div>

    <div class="absolute bottom-5 left-10 right-10 lg:bottom-8 flex z-50 p-4 space-x-2 justify-center items-center text-gray-800">
      <button
        v-for="category in categories" :key="category.id" @click="toggleCategory(category)"
        class=" transition-all duration-300 group flex items-center text-xs lg:text-sm font-medium py-2 px-3 rounded shadow shadow-gray-300"
        :class="selected_categories.includes(category.id) ? 'bg-heimatgenuss-pink text-white shadow-heimatgenuss-pink': 'bg-white hover:bg-pink-500 hover:text-white hover:shadow-pink-500' "
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.093 25.847" class="w-6 h-6 mr-1 group-hover:text-white transition-colors duration-300" :class="[selected_categories.includes(category.id) ? 'text-white': category.color_classes.text]">
          <path fill="currentColor" d="M9.046 0A9.04 9.04 0 0 0 0 9.046c0 6.785 9.046 16.8 9.046 16.8s9.046-10.016 9.046-16.8A9.04 9.04 0 0 0 9.046 0Zm0 12.277a3.231 3.231 0 1 1 3.231-3.231 3.232 3.232 0 0 1-3.231 3.231Z" />
        </svg>
        <span>{{ category.name }}</span>
      </button>
    </div>
    <GMapMap
      class="w-full h-full"
      :center="center"
      :zoom="zoom"
      :options="{
        styles: mapStyles,
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: true
      }"
      map-type-id="terrain"
    >
        <GMapCluster :renderer="renderer" :minimumClusterSize="3">
          <GMapMarker
            :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false"
            @click="markerClicked(m, index)"
            :icon='{
                url: selected_marker === index ? clickedIcon: icon,
                scaledSize: {width: 36, height: 36},
                labelOrigin: {x: 0, y: 0}
            }'
          />
        </GMapCluster>
        <GMapInfoWindow
          :options="infoWindow.options"
          :position="infoWindow.pos"
          :opened="infoWindow.open"
          ref="infoWindow"
          @closeclick="closeInfoWindow()"
        >
          <div class="text-gray-800">
            <div class="flex flex-col md:flex-row mt-1 md:mt-0 md:space-x-2 lg:space-x-4">
              <img  v-if="infoWindow.content.thumbnail" :src="infoWindow.content.thumbnail" :alt="'Vorschau:' + infoWindow.content.title" class="w-40 h-auto object-contain object-center bg-gray-200" />
              <div class="flex flex-col text-left py-2">
                <div class="text-lg font-bold leading-tight">{{ infoWindow.content.title }}</div>
                <address class="text-sm my-0.5">
                  <span>{{ infoWindow.content.street }}</span><br/>
                  <span>{{ infoWindow.content.postcode }}</span> <span>{{ infoWindow.content.city }}</span>
                </address>
                <a :href="infoWindow.content.url" class="mt-auto text-sm text-heimatgenuss-pink hover:text-pink-500 transition-colors duration-300 flex items-center justify-end focus:outline-none focus:underline">
                  <span>Zum Partner</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 ml-1">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </GMapInfoWindow>
    </GMapMap>
  </div>
</template>

<script>
import axios from 'axios'
import MapStyles from './styling';
import _ from 'lodash';

const clickedIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MiA0MiI+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIiB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxmZU9mZnNldCBkeT0iMyIvPgogICAgICA8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxLjUiIHJlc3VsdD0iYmx1ciIvPgogICAgICA8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIuMzEiLz4KICAgICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJpbiIgaW4yPSJibHVyIi8+CiAgICAgIDxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnIGRhdGEtbmFtZT0iR3JvdXAgMjUyMyI+CiAgICA8ZyBmaWx0ZXI9InVybCgjYSkiPgogICAgICA8Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA4NyIgY3g9IjE2LjUiIGN5PSIxNi41IiByPSIxNi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjUgMS41KSIgZmlsbD0iI2FjMmY3YyIvPgogICAgPC9nPgogICAgPHBhdGggZGF0YS1uYW1lPSJJY29uIG1hdGVyaWFsLWxvY2F0aW9uLW9uIiBkPSJNMjEgOC43NGE2LjQ4IDYuNDggMCAwIDAtNi40OCA2LjQ4YzAgNC44NiA2LjQ4IDEyLjA0IDYuNDggMTIuMDRzNi40OC03LjE4IDYuNDgtMTIuMDRBNi40OCA2LjQ4IDAgMCAwIDIxIDguNzRabTAgOC44YTIuMzIgMi4zMiAwIDEgMSAyLjMyLTIuMzJBMi4zMiAyLjMyIDAgMCAxIDIxIDE3LjU0WiIgZmlsbD0iI2ZmZiIvPgogIDwvZz4KPC9zdmc+Cg==";

const Icon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MiA0MiI+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIiB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxmZU9mZnNldCBkeT0iMyIvPgogICAgICA8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxLjUiIHJlc3VsdD0iYmx1ciIvPgogICAgICA8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIuMzEiLz4KICAgICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJpbiIgaW4yPSJibHVyIi8+CiAgICAgIDxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnIGRhdGEtbmFtZT0iR3JvdXAgMjUyMyI+CiAgICA8ZyBmaWx0ZXI9InVybCgjYSkiPgogICAgICA8Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA4NyIgY3g9IjE2LjUiIGN5PSIxNi41IiByPSIxNi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjUgMS41KSIgZmlsbD0iI2ZmZiIvPgogICAgPC9nPgogICAgPHBhdGggZGF0YS1uYW1lPSJJY29uIG1hdGVyaWFsLWxvY2F0aW9uLW9uIiBkPSJNMjEgOC43NGE2LjQ4IDYuNDggMCAwIDAtNi40OCA2LjQ4YzAgNC44NiA2LjQ4IDEyLjA0IDYuNDggMTIuMDRzNi40OC03LjE4IDYuNDgtMTIuMDRBNi40OCA2LjQ4IDAgMCAwIDIxIDguNzRabTAgOC44YTIuMzIgMi4zMiAwIDEgMSAyLjMyLTIuMzJBMi4zMiAyLjMyIDAgMCAxIDIxIDE3LjU0WiIgZmlsbD0iI2FlMzA3ZCIvPgogIDwvZz4KPC9zdmc+Cg==";

export default {
  name: 'Radar',
  props: {
    center: {
      type: Object,
      default: () => ({
        'lat': 51.3417825, 'lng': 12.3936349
      }),
    },
    endpoint: {
      type: String,
      default: `/api/partners/?locale=de&fields=*&order=title`,
    },
    zoom: {
      type: Number,
      default: 8,
    },
    apikey: {
      type: String,
      default: '',
    },
    categories: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      icon: Icon,
      clickedIcon: clickedIcon,
      mapStyles: MapStyles,
      loaded: false,
      infoWindow: {
        pos: null,
        open: false,
        marker_index: null,
        content: {
          title: '',
          street: '',
          city: '',
          postcode: '',
          thumbnail: null,
          url: '',
        },
        options: {
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
      },
      objectPool: {},
      markers: [],
      selected_marker: null,
      selected_regions: [],
      selected_categories: [],
    }
  },
  created() {
    this.renderer = {
      render: ({ count, position }, stats) => {
        const color =
          count > Math.max(10, stats.clusters.markers.mean)
            ? "#ae307d"
            : "#e3af05";

        // create svg url with fill color
        const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity=".6" r="70" />
            <circle cx="120" cy="120" opacity=".3" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="110" />
            <circle cx="120" cy="120" opacity=".1" r="130" />
          </svg>`);
        return new google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
          },
          label: {
            text: String(count),
            color: "rgba(255,255,255,0.9)",
            fontSize: "12px",
          },
          // adjust zIndex to be above other markers
          zIndex: 1000 + count,
        });
      },
    };
  },
  mounted() {
    //window.addEventListener('resize', this.handleResize);
    this.loadObjects();
    //this.handleResize();
  },
  methods: {
    markerClicked: function(marker, idx) {
      if (this.selected_marker === idx) {
        this.closeInfoWindow();
      } else {
        this.selected_marker = idx;
        this.openInfoWindow(marker);
      }
    },
    closeInfoWindow: function() {
      this.selected_marker = null;
      this.infoWindow.open = false;
    },

    openInfoWindow: function(marker) {
      this.infoWindow.open = true;
      //set info window position
      this.infoWindow.pos = marker.position;
      this.infoWindow.content = marker.content;
    },
    toggleCategory(category) {
      if (this.selected_categories.includes(category.id)) {
        this.selected_categories = this.selected_categories.filter(c => c !== category.id);
      } else {
        this.selected_categories = this.selected_categories.concat(category.id);
      }
      this.updateMarkers();
    },
    toggleRegion(region) {
      if (this.selected_regions.includes(region.id)) {
        this.selected_regions = this.selected_regions.filter(r => r !== region.id);
      } else {
        this.selected_regions = this.selected_regions.concat(region.id);
      }
      this.updateMarkers();
    },
    loadObjects() {
      let limit = 50;
      let total = 0;
      let self = this;

      // api key auth header
      axios.defaults.headers.common = {
        'Authorization': `Api-Key ${this.apikey}`
      };

      // for each region load all objects
      this.regions.forEach(region => {
        self.objectPool[region.id] = [];
        axios.get(self.endpoint + `&child_of=${region.id}&limit=${limit}&offset=0`).then(response => {
          total = response.data.meta.total_count;
          self.objectPool[region.id] = self.objectPool[region.id].concat(response.data.items);

          return Promise.all(_.map(
            _.range(self.objectPool[region.id].length, total, limit),
            offset => axios.get(self.endpoint + `&child_of=${region.id}&limit=${limit}&offset=${offset}`)
          ));
        }).then(results => {
          self.objectPool[region.id] = self.objectPool[region.id].concat(
            _.reduce(results, (items, response) => items.concat(response.data.items), []));
        }).finally(() => {
          this.loaded = true
          this.updateMarkers();
        });
      });

    },
    updateMarkers() {
      // filter objects by selected categories and regions
      let regionObjects;

      if (this.selected_regions.length === 0) {
        regionObjects = _.values(this.objectPool);
      } else {
        regionObjects = _.values(_.pick(this.objectPool, this.selected_regions));
      }

      regionObjects = _.flatten(regionObjects);

      let filtered = regionObjects.filter(o => {
        //let object_categories = o.categories.map(c => c.id);
        if (o.main_category === null) {
          return false;
        }
        let has_category = this.selected_categories.includes(o.main_category.id); // some(c => object_categories.includes(c));
        return has_category || this.selected_categories.length === 0;
      });

      this.markers = filtered.map(o => {
        return {
          position: {
            lat: o.geolocation.lat, lng: o.geolocation.lng
          },
          content: {
            title: o.title,
            thumbnail: o.preview_image_thumbnail?.url,
            street: o.street,
            city: o.city,
            postcode: o.postcode,
            url: o.meta.html_url,
          },
        }
      });
    },
  }
}
</script>
